import LazyLoad from 'react-lazyload';
import ModalImage from "react-modal-image";
import { Masonry } from "masonic";
import {saveAs} from 'file-saver';
import { Card, Button, Tooltip  } from "flowbite-react";
import { PiDownloadSimple, PiCopySimple  } from "react-icons/pi";
// import Image from "next/image";

function createImage(options) {
    options = options || {};
    const img = (Image) ? new Image() : document.createElement("img");
    if (options.src) {
        img.src = options.src;
    }
    return img;
  }

const copyToClipboard = async (pngBlob) => {
    try {
      await navigator.clipboard.write([
        // eslint-disable-next-line no-undef
        new ClipboardItem({
          [pngBlob.type]: pngBlob
        })
      ]);
      console.log("Image copied");
    } catch (error) {
      console.error(error);
    }
  };
  
const convertToPng = (imgBlob) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const imageEl = createImage({ src: window.URL.createObjectURL(imgBlob) });
    imageEl.onload = (e) => {
      canvas.width = e.target.width;
      canvas.height = e.target.height;
      ctx.drawImage(e.target, 0, 0, e.target.width, e.target.height);
      canvas.toBlob(copyToClipboard, "image/png", 1);
    };
  };
  
const copyImg = async (src) => {
    const img = await fetch(src);
    const imgBlob = await img.blob();
    const extension = src.split(".").pop();
    const supportedToBeConverted = ["jpeg", "jpg", "gif"];
    if (supportedToBeConverted.indexOf(extension.toLowerCase())) {
      return convertToPng(imgBlob);
    } else if (extension.toLowerCase() === "png") {
      return copyToClipboard(imgBlob);
    }
    console.error("Format unsupported");
    return;
  };

const downloadFile = (file) => {
    saveAs(file, file.split('/').pop())
    return;
  }

const ModalImg = (props) => (
          <ModalImage className='object-cover rounded-lg shadow border border-zinc-400 dark:border-zinc-600
              w-full h-full hover:scale-105'
              small={props.thumb}
              large={props.full}
              alt={`GigaChad Meme ${props.index}`}
              title={`GigaChad Meme ${props.index}`}
          >
          </ModalImage>        
);

function MasonryCard({ index, data: {full, thumb}, width }) {
    
    return (
        <LazyLoad height={200} offset={150} once>
        <Card
            className='max-w-sm flex flex-col items-center justify-center border-zinc-400 dark:border-zinc-700 bg-zinc-500 dark:bg-zinc-900'
            renderImage={() => <ModalImg full={full} thumb={thumb} index={index}></ModalImg>}
        >

        {/* <div className='items-center'> */}
            <div className="inline-flex rounded-md shadow-sm " role="group">
                <Tooltip content='copy' className='bg-zinc-600' placement='left'>
                <Button onClick={() => copyImg(full)}
                        className='px-5 py-0.5 text-xs font-medium text-gray-900 bg-zinc-500 dark:bg-zinc-900
                            border border-zinc-900 dark:border-zinc-400 rounded-s-lg 
                            hover:bg-zinc-600 hover:text-white 
                            focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white 
                            dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700'
                    >   
                        <PiCopySimple></PiCopySimple>
                    </Button>
                </Tooltip>                
                <Tooltip content='download' className='bg-zinc-600' placement='right'>
                    <Button onClick={() => downloadFile(full)}
                        className='px-5 py-0.5 text-xs font-medium text-gray-900 bg-zinc-500 dark:bg-zinc-900
                            border border-zinc-900 dark:border-zinc-400 rounded-s-lg 
                            hover:bg-zinc-600 hover:text-white 
                            focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white 
                            dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700'
                    >
                        <PiDownloadSimple></PiDownloadSimple>
                    </Button>
                </Tooltip>
            </div>                
        {/* </div> */}

        </Card>
        </LazyLoad>

    )
}

// function MasonryComponent(props)
const MasonryComponent = (props) => (
    <Masonry
        items={props.images}
        columnGutter={6}
        columnWidth={250}
        overscanBy={2}
        render={MasonryCard}
     />
)

export {MasonryCard, MasonryComponent}