// Layout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import {NavBar} from './components/navBar';
import {Footer} from './components/footer'

function Layout() {
  return (
    <>
    <div className='flex flex-col justify-between bg-zinc-200 dark:bg-zinc-800'>
      <header>
        <NavBar />
      </header>
      <main className="h-full min-h-screen bg-inherit dark:bg-inherit pt-24 p-10">
        <Outlet />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>    
    </>

  );
}

export default Layout;