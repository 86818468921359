// import { Masonry } from "react-visual-grid";
import React, {useState, useEffect} from 'react';
import {MasonryComponent} from '../components/MasonryCard';
import {LoadingCircle} from '../components/loader';
import { Helmet } from "react-helmet";

function Gallery() {
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const finishedRequest = (data) => {
        setImages(data)
        setIsLoading(false)
    }
    useEffect(() => {
        setIsLoading(true)
        fetch('https://gigachadmemes.com/api/gallery') //5 
            .then(response => response.json()) //6 
            .then(data => finishedRequest(data));
    }, []);

    return (
        <>
        <Helmet>
            <title>GigaChad Meme gallery sorted</title>
            <link rel='canonical' href='https://www.gigachadmemes.com/gallery'></link>
        </Helmet>        
        <div className="content-center">
            {isLoading ? 
                <LoadingCircle></LoadingCircle> :
                <MasonryComponent images={images}></MasonryComponent>                
            }
        </div>
        </>

    );
  }
  
  export default Gallery;