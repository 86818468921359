import React from 'react';

import { RiTwitterXFill,RiTelegramFill, RiInstagramFill, RiTiktokFill } from "react-icons/ri";
import {colors_primary} from '../styles';

const platforms = [
    { name: "Gigachad",  link: 'https://x.com/gigachad', icon: 'https://www.google.com/s2/favicons?domain=x.com'},
    { name: 'Berlin.1969', link: 'https://instagram.com/berlin.1969/', icon: 'https://www.google.com/s2/favicons?domain=instagram.com'},
    { name: "GigaChadSol", link: 'https://t.me/GigaChadSol', icon: 'https://www.google.com/s2/favicons?domain=t.me'},
    { name: "GIGACHAD_meme", link: 'https://x.com/GIGACHAD_meme', icon: 'https://www.google.com/s2/favicons?domain=x.com'},
    { name: "GigaChadSolana", link: 'https://www.instagram.com/gigachadsolana/', icon: 'https://www.google.com/s2/favicons?domain=instagram.com'},
    { name: "GigaChadSolana", link: 'https://www.tiktok.com/@gigachadsolana', icon: 'https://www.google.com/s2/favicons?domain=www.tiktok.com'},
]

const extralinks = [
    { name: "GIGAMEMEDROP", link: 'https://t.me/GIGAMEMEDROP', icon: 'https://www.google.com/s2/favicons?domain=t.me'},
    { name: 'KnowYourMeme', link: 'https://knowyourmeme.com/memes/gigachad', icon: 'https://www.google.com/s2/favicons?domain=knowyourmeme.com'},
    { name: 'ImgFlip', link: 'https://imgflip.com/memesearch?q=gigachad', icon: 'https://www.google.com/s2/favicons?domain=imgflip.com'},
    { name: 'MemeDroid', link: 'https://www.memedroid.com/memes/tag/gigachad', icon: 'https://www.google.com/s2/favicons?domain=memedroid.com'},
]

const gigachadsol = [
    { name: "GigaChadSolana", link: 'https://gigachadsolana.com', icon: 'https://www.google.com/s2/favicons?domain=gigachadsolana.com'},
    { name: "LinkTree", link: 'https://linktr.ee/gigachadlinks', icon: 'https://www.google.com/s2/favicons?domain=linktr.ee'},
    { name: "HolderScan", link: 'https://holderscan.com/token/63LfDmNb3MQ8mw9MtZ2To9bEA2M71kZUq5tiJxcqj9', icon: 'https://www.google.com/s2/favicons?domain=holderscan.com'},
    { name: "DexTools", link: 'https://www.dextools.io/app/en/token/gigachad', icon: 'https://www.google.com/s2/favicons?domain=www.dextools.io'},
    { name: "CoinMarketCap", link: 'https://coinmarketcap.com/currencies/gigachad-meme/', icon: 'https://www.google.com/s2/favicons?domain=coinmarketcap.com'},
    { name: "CoinGecko", link: 'https://www.coingecko.com/nl/coins/gigachad-2', icon: 'https://www.google.com/s2/favicons?domain=coingecko.com'},
]

function Footer() {
    return (
    <div className={`${colors_primary} flow-root w-full py-4 border-zinc-400 dark:border-zinc-400 border-t-2`}>
        <div className='px-2 sm:px-3 lg:px-24 '>
            <div className='grid grid-cols-3 divide-x divide-zinc-400 content-center'>
                <div className="flex flex-col space-x-5 justify-center items-center mb-4">
                    
                    <span className="text-sm text-center block text-dimWhite pb-2"><b>GigaChad Crypto</b></span>
                    <br></br>        
                    <div className='flex flex-row'> 
                        <ul className='md:columns-2 sm:columns-1'>
                        {gigachadsol.map((x, index) => {
                                return <li className='pt-1'>
                                        <a target="_blank" href={x.link} className='flex flex-row text-xs dark:text-dimWhite hover:dark:text-white text-dimWhite hover:text-white' >
                                            <img src={x.icon} className='max-w-4'></img>
                                            &nbsp;{x.name}
                                        </a>
                                    </li>
                            })}
                        </ul>
 
                    </div>        
                </div>
                <div className="flex flex-col space-x-5 justify-center items-center mb-4">
                    <span className="text-sm text-center block text-dimWhite pb-2"><b>GigaChad Memes</b></span>
                    <br></br>        
                    <div className='flex flex-row'> 
                        <ul className='md:columns-2 sm:columns-1'>
                            {extralinks.map((x, index) => {
                                    return <li className='pt-1'>
                                            <a target="_blank" href={x.link} className='flex flex-row text-xs dark:text-dimWhite hover:dark:text-white text-dimWhite hover:text-white' >
                                                <img src={x.icon} className='max-w-4'></img>
                                                &nbsp;{x.name}
                                            </a>
                                        </li>
                                })}
                            </ul>
                    </div>        
                </div>
                <div className="flex flex-col space-x-5 justify-center items-center mb-4">
                    <span className="text-sm text-center block text-dimWhite pb-2"><b>GigaChad Socials</b></span>
                    <br></br>        
                    <div className='flex flex-row'> 
                        <ul className='md:columns-2 sm:columns-1'>
                            {platforms.map((x, index) => {
                                    return <li className='pt-1'>
                                            <a target="_blank" href={x.link} className='flex flex-row text-xs dark:text-dimWhite hover:dark:text-white text-dimWhite hover:text-white' >
                                                <img src={x.icon} className='max-w-4'></img>
                                                &nbsp;{x.name}
                                            </a>
                                        </li>
                                })}
                            </ul>
                    </div>        
                </div>                
                {/* <div className="flex flex-col space-x-5 justify-center items-center mb-4">
                    <span className="text-sm text-center block text-dimWhite "><b>GigaChadSol Socials</b></span>
                    <br></br>        
                    <div className='flex flex-row'> 
                        {platforms.map((x, index) => {
                                return <a href={x.link} className='text-xl px-6 pt-3' >
                                    <x.icon key={index} className="hover:text-white text-dimWhite" />
                                </a>
                            })}
                    </div>        
                </div>                                 */}
            </div>
           
            <hr className={`bg-zinc-400 h-px my-3 border-0`}></hr>        
            <span className="text-sm text-center text-dimWhite block">© $GIGA Chad 2024, questions can be directed on X to &nbsp;
                <a className='hover:text-white' href='https://x.com/Pisselingh'>@Pisselingh</a></span>

              
        </div>
 
</div>
)}

export {Footer}