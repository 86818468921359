

const routes = [
    {id: '/', title: 'Search'},
    {id: '/random', title: 'Random'},
    {id: '/gallery', title: 'Gallery'},
    {id: '/video', title: 'Video'},
    {id: '/legal', title: 'Legal'},
  ]

export {routes}