import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Layout from './Layout';
import Search from './pages/Search';
import Random from './pages/Random';
import Gallery from './pages/Gallery';
import Video from './pages/Video';
import Legal from './pages/Legal';
if (localStorage.getItem('theme') === null) {
  localStorage.setItem("theme", 'dark');
}
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Search />} />
          <Route path="/random" element={<Random />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/video" element={<Video />} />
          <Route path="/legal" element={<Legal />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
