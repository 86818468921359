import React, {useState} from 'react';
import {  NavLink, useLocation } from 'react-router-dom';
import {colors_primary} from '../styles';
import {routes} from './routes';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import Switcher from '../components/Switcher'
function NavBar() {
    const location = useLocation();
    // const current_path = location.pathname.substring(1)
    const active_page = routes.find(item => item.id === location.pathname)
    const [active, setActive] = useState(active_page.title);
    const [nav, setNav] = useState(false);
  
    // Toggle function to handle the navbar's display
    const handleNav = () => {
      setNav(!nav);
    };    
    // <nav class="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
    return (
    <nav className={`${colors_primary} w-screen fixed z-20 justify-between items-center navbar border-zinc-400 dark:border-zinc-400 border-b-2`}>
        <div className='max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-1'>

        <a href="/" className="px-6 hover:scale-105">
            <img src="https://static.7ce.nl/banner.png" className="h-8" alt="Gigachad Logo"/>
            <span className=""></span>
        </a>

        <div className="list-none sm:flex hidden justify-end items-center flex-1">
       
            {routes.map((nav, index) => (
                <NavLink
                to={nav.id}
                key={index}
                className={`font-normal cursor-pointer hover:text-white text-[16px] ${
                    active === nav.title ? "text-white" : "text-dimWhite"
                } ${index === routes.length - 1 ? "mr-0" : "mr-10"}`}
                onClick={() => setActive(nav.title)}
                >
                {nav.title}
                </NavLink>
            ))}       
        <div className="px-6">
            <Switcher />        
        </div>        
        </div>

        <div className="sm:hidden flex flex-1 justify-end items-center p-1 w-full">
            <div className="px-6">
                <Switcher />        
            </div>            
            <div onClick={handleNav} className='block md:hidden'>
                {nav ? 
                    <AiOutlineClose className={`${colors_primary} fill-zinc-100`} size={30} /> : 
                    <AiOutlineMenu className={`${colors_primary} fill-zinc-100`} size={30} />
                }
            </div>

            <div
                className={`${
                nav
                ? `fixed md:hidden sm:hidden  border-r ${colors_primary}  border-zinc-400 dark:border-zinc-400 border-b-2 shadow z-50 ease-in-out duration-500`
                : 'ease-in-out hidden duration-500 fixed '
            }
            text-center bg-black-gradient top-12 h-screen w-screen sidebar`}
            >
                <div className="list-none flex justify-end items-start text-center flex-1 flex-col">
                    <br></br><br></br>
                    {routes.map((nav, index) => (
                        <NavLink
                        to={nav.id}
                        key={index}
                        className={`font-poppins font-normal cursor-pointer w-full rounded-lg hover:bg-zinc-300 hover:dark:bg-zinc-800 text-[16px] ${
                        active === nav.title ? "text-white" : "text-dimWhite"
                        } ${index === routes.length - 1 ? "mr-0" : "mr-10"}`}
                        onClick={() => { setActive(nav.title); setNav(!nav);}}>
                            {nav.title}
                        </NavLink>
                    ))}
                </div>
            </div>
        </div>

        </div>        
    </nav>
    )
};

export {NavBar};