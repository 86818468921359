// import { Masonry } from "react-visual-grid";
import React, {useState, useEffect} from 'react';
import {MasonryComponent} from '../components/MasonryCard';
import { useDebounce } from "use-debounce";
import {LoadingCircle} from '../components/loader';
import { Helmet } from "react-helmet";

function Search() {
    const [images, setImages] = useState([]);
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [debouncedValue] = useDebounce(search, 1000);

    const doSearchRequest = () => {
        fetch('https://gigachadmemes.com/api/search', {
            method: "POST",
            headers:{
            'Content-Type': 'application/x-www-form-urlencoded'         
            },
            body: new URLSearchParams({
            "query": `${search}`
            })
        }).then(response => response.json()).then(data => finishedRequest(data));          
    }

    const handleChange = (event) => {
        setSearch(event.target.value);
        
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            doSearchRequest()
        } 
    }

    const finishedRequest = (data) => {
        setImages(data)
        setIsLoading(false)

    }

    useEffect(() => {
        if (search === '') {
            setIsLoading(true)            
            fetch('https://gigachadmemes.com/api/random', {
                method: "POST",
                headers:{
                'Content-Type': 'application/x-www-form-urlencoded'         
                },
                body: new URLSearchParams({
                "amount": `50`
                })
            }).then(response => response.json()).then(data => finishedRequest(data));
        }
    }, []);

    useEffect(() => {
        if (search !== '') {
            setIsLoading(true)
            doSearchRequest()
        }
    }, [debouncedValue]);    

    return (
        <>
        <Helmet>
            <title>GigaChad Meme Search tool</title>
            <link rel='canonical' href='https://wwww.gigachadmemes.com'></link>            
        </Helmet>
        <div className="content-center">
            <div class='w-full max-w-lg mx-auto bg-dimWhite dark:bg-gray-500 rounded-lg shadow-xl'>
                <div
                    class="flex items-center px-1 py-1 text-gray-500 dark:text-dimWhite group hover:ring-1 hover:ring-gray-300 focus-within:!ring-2 ring-inset focus-within:!ring-zinc-200 rounded-md">
                    <svg class="mr-2 h-5 w-5 stroke-slate-400" fill="none" viewBox="0 0 24 24" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z">
                        </path>
                    </svg>
                    <input
                        class="block w-full appearance-none bg-transparent text-base text-gray-700 dark:text-dimWhite placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
                        placeholder="Search"
                        aria-label="Search images"
                        // id="headlessui-combobox-input-:r5n:"
                        // role="combobox"
                        type="search"
                        aria-expanded="false"
                        aria-autocomplete="list"
                        onChange={handleChange} 
                        onKeyDown={handleKeyDown}
                        value={search}
                        />
                </div>
            </div>
            <br></br>
            {isLoading ? 
                <LoadingCircle></LoadingCircle> :
                <MasonryComponent images={images}></MasonryComponent>                
            }
        </div>
        </>
    );
  }
  
  export default Search;