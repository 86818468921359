import { Helmet } from "react-helmet";


function Search() {
    return (
        <>
        <Helmet>
            <title>GigaChad Memes website legal disclaimer.</title>
            <link rel='canonical' href='https://www.gigachadmemes.com/legal'></link>
        </Helmet>        

        <p className='text-center bold py-10 dark:text-dimWhite'>
            <b>
            <h2>Disclaimer</h2>
<br></br><hr className=""></hr><br></br>

<a className="dark:text-dimWhite hover:dark:text-white text-black hover:text-zinc-700" href='https://gigachadmemes.com'>gigachadmemes.com</a> is an independent community website with no official affiliation or association with the $GIGA memecoin on the Solana blockchain, or with Giga Fitness LLC. <br></br>
All images and content shared on this site are sourced from the community and intended solely for entertainment purposes.
<br></br><br></br>
If you are the copyright holder of any image displayed on this site and would like to request its removal, please contact us directly on the X platform (formerly Twitter) <a className="dark:text-dimWhite hover:dark:text-white text-black hover:text-zinc-700" href='https://x.com/Pisselingh'>@Pisselingh</a>. <br></br>
<br></br><br></br>
By using this website, you acknowledge that <a className="dark:text-dimWhite hover:dark:text-white text-black hover:text-zinc-700" href='https://gigachadmemes.com'>gigachadmemes.com</a> assumes no responsibility for the content posted and does not endorse or represent any third-party brands, organizations, or entities.
<br></br>
You also agree to complete 30 pushups and read the <a className="dark:text-dimWhite hover:dark:text-white text-black hover:text-zinc-700" href='https://x.com/gigagriff/status/1757936685264818297' target='_blank'>/threads</a> before browsing.
<br></br><br></br>
Please note that investing in cryptocurrency carries inherent risks and may result in the loss of funds. <a className="dark:text-dimWhite hover:dark:text-white text-black hover:text-zinc-700" href='https://gigachadmemes.com'>gigachadmemes.com</a> does not provide financial advice or investment recommendations of any kind.
            </b>
        </p>
        </>

    );
  }
  
  export default Search;