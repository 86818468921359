import { Helmet } from "react-helmet";


function Video() {
    return (
        <>
        <Helmet>
            <title>GigaChad Meme video's and gifs</title>
            <link rel='canonical' href='https://www.gigachadmemes.com/video'></link>
        </Helmet>
        <div className="p-1 pt-8">
        <h1>
            Video Gallery TBA
        </h1>            
        </div>        
        </>


    );
  }
  
  export default Video;